import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import s from './Card.module.scss';
import CheckboxPure from '../Checkbox/CheckboxPure';
import { selectIsModerator } from '../../../redux/slices/userInfoSlice';

const Card = ({
  children, actions, checked, onCheck = () => {}, active, onClick = () => {}, filter, closable,
  check, short, size = 'm', displayOnlyTop = false, isOnlyTop = false, setIsOnlyTop,
}) => {
  const ref = useRef(null);
  const isModerator = useSelector(selectIsModerator);
  useEffect(() => {
    if (closable) {
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [ active ]);

  return (
    <div
      className={classNames(s.card, s[`size_${size}`], {
        [s.cardShort]: short,
        [s.closable]: closable,
        [s.checked]: checked,
        [s.check]: check,
        [s.filter]: filter,
        [s.nonCloseable]: !closable,
        [s.eventAccessCard]: size === 's' && !actions,
      })}>
      {closable && (
        <div
          ref={ref}
          onClick={onClick}
          className={classNames(s.arrowField, { [s.arrowFieldShort]: short })}>
          <div className={classNames(s.arrow, { [s.arrowActive]: active })} />
        </div>
      )}
      <div onClick={onCheck} className={classNames(s.content, { [s.contentActive]: active })}>
        {children}
      </div>
      {displayOnlyTop && (
        <div onClick={() => setIsOnlyTop(prev => !prev)} className={s.headingCheckboxRoot}>
          <div className={s.headingCheckbox}>
            <CheckboxPure big checked={isOnlyTop} />
          </div>
          <div className={s.headingCheckboxTitle}>
            <div>Show Most Frequently</div>
            <div>Asked Questions Only</div>
          </div>
        </div>
      )}
      {!isModerator && filter && <div onClick={filter} className={s.filterIcon} />}
      {!filter && actions && (
        <div onClick={onCheck || null} className={s.actions}>
          {actions}
        </div>
      )}
    </div>
  );
};

export default Card;
