import { createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS } from '../../api/axios';
import { getErrorMessage } from '../../helpers/api';
import { setAlert } from '../slices/alertSlice';

// GET FILTERS OPTIONS
export const getSatisfactionAnalysThunk = createAsyncThunk(
  'satisfactionAnalys/getSatisfactionAnalys',
  async (data, { dispatch, getState }) => {
    const response = await AXIOS.post('/analytics/satisfaction', data).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getSatisfactionAnalysThunkPending = (state) => {
  state.isLoading = true;
};

const getSatisfactionAnalysThunkFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.fullAnalys = payload;
  state.patientVisitNo = 0;
  state.analys = payload[0];
};

export const satisfactionExtraReducers = (builder) => {
  builder
    .addCase(getSatisfactionAnalysThunk.pending, getSatisfactionAnalysThunkPending)
    .addCase(getSatisfactionAnalysThunk.fulfilled, getSatisfactionAnalysThunkFulfilled);
};
