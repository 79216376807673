import classNames from 'classnames';
import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Progress } from '../../../../../../components/UI';
import s from './GroupChartView.module.scss';
import LineClampItem from '../../../../../../components/UI/LineClampItem/LineClampItem';
import PhotoPopup from '../../../../../../components/PhotoPopup/PhotoPopup';
import CarouselPopup from '../../../../../../components/CarouselPopup/CarouselPopup';
import { getTop10Items } from '../../../../../../helpers/utils';
import VideoPopup from '../../../../../../components/VideoPopup/VideoPopup';
import NoItem from '../../../../../../components/NoItem/NoItem';

const GroupChartView = ({ data, isOnlyTop }) => {
  const [ showPhoto, setShowPhoto ] = useState(null);
  const [ showPhotos, setShowPhotos ] = useState(null);
  const [ showVideo, setShowVideo ] = useState(null);
  const files = useSelector(state => state.reportAnalys.analys.files);
  const videos = useSelector(state => state.reportAnalys.analys.videos);
  const getPhotoPaths = name => files.find(item => item.name === name) || null;
  const getVideoPaths = name => videos.find(item => item.name === name) || null;
  const {
    charts, answersCategories, question,
  } = data;
  const chapterType = question.type;
  const [ showLabels, setShowLabels ] = useState(false);
  const finalCharts = useMemo(() => (
    isOnlyTop ? getTop10Items(charts) : charts
  ), [ charts, isOnlyTop ]);

  const haveAnalysis = useMemo(() => (
    charts.filter(el => el.count > 0).length > 0
  ), [ charts ]);

  return (
    <div key={'GroupChart'} className={s.groupChart1Root}>
      <div className={s.groupChart1Content}>
        {
          (haveAnalysis && finalCharts?.length > 0 && answersCategories?.length > 2)
          && (<div className={classNames(s.showLabelsText)} onClick={() => setShowLabels(!showLabels)}>
            {!showLabels && <div className={s.labelBox}>
              <span className={s.showLabelArrow} />
              <label>Show Labels</label>
            </div>}
            {showLabels && <div className={s.labelBox}>
              <label>Hide Labels</label>
              <span className={s.hideLabelArrow} />
            </div>}
          </div>)
        }
        {finalCharts !== undefined
          && finalCharts.map((item, i) => (
            item.count !== 0 && <div key={item.id} className={s.groupChartItem}>
              <div className={classNames(s.groupChartTitle, {
                [s.showingLabels]: showLabels,
              })}>
                {answersCategories.map(category => (
                  ![ 'IMAGE', 'Video', 'VIDEO', 'count', 'Comments', 'Category' ].includes(category)
                  && (
                    <section key={`${category}catIndx`} className={s.groupChartTitleBox}>
                      {showLabels && <div className={s.groupChartHeaderTitle}>{category}</div>}
                      <div className={s.groupChartHeaderValue}>
                        {<LineClampItem key={item[category]} content={item[category]} />}
                      </div>
                    </section>
                  )))}
              </div>
              <div className={classNames(s.groupChartInfo, {
                [s.showingLabels]: showLabels,
              })}>
                <div className={s.progressItemInfo}>
                  <Progress key={`${item.id}Progress`} percent={item.percentage || 0} />
                  {chapterType !== 'noGroups' && answersCategories[0] === 'Category'
                    && <div className={s.progressItemLabel}>{item[answersCategories[0]]}</div>}
                </div>
                <div className={s.progressItemPercent}>
                  {item.count} - ({item.percentage || 0}%)
                </div>
                <div className={s.groupChartImage}>
                  {answersCategories.map((category) => {
                    if (category === 'IMAGE') {
                      const image = item[category];
                      const isImage = item[category].split(',').length === 1;
                      const isImages = item[category].split(',').length > 1;
                      let imageFile;
                      let imageFiles;
                      if (isImage) {
                        imageFile = getPhotoPath(item[category]);
                      }
                      if (isImages) {
                        imageFiles = item[category].split(',').map(im => im.trim()).map(im => getPhotoPaths(im));
                      }
                      return (
                        (isImage || isImages) && <span key={item}>
                          {isImage && image && (
                            <span className={classNames({ [s.imageView]: image })}>
                              <span onClick={() => image && setShowPhoto(imageFile)}>Image</span>
                            </span>
                          )}
                          {isImages && imageFiles && (
                            <span className={classNames({ [s.imageView]: image })}>
                              <span onClick={() => imageFiles && setShowPhotos(imageFiles)}>
                                Images
                              </span>
                            </span>
                          )}
                        </span>
                      );
                    }
                    if (category === 'VIDEO') {
                      const video = item[category];
                      const videoFile = getVideoPaths(item[category]);
                      return (
                        <span
                          key={category}
                          length={answersCategories.length}
                        >
                          {video && videoFile && <span
                            className={classNames({ [s.imageView]: video })}
                            onClick={() => videoFile && setShowVideo(getVideoUrl(videoFile))}>
                            Video
                          </span>}
                        </span>
                      );
                    }
                    return true;
                  })
                  }
                </div>
              </div>
            </div>
          ))}
        {!haveAnalysis && <div className={s.noEvent}><NoItem title={'Chart'} /></div>}
      </div>
      {showPhoto
        && <PhotoPopup img={showPhoto} onClose={() => setShowPhoto(null)} />
      }
      {showPhotos
        && <CarouselPopup files={showPhotos} onClose={() => setShowPhotos(null)} />
      }
      {showVideo
        && <VideoPopup video={showVideo} onClose={() => setShowVideo(null)} />
      }
    </div>
  );
  function getPhotoPath(name) {
    const file = files.find(item => item.name === name);
    return file ? process.env.REACT_APP_IMAGE_URL + file.mozJpeg : '';
  }

  function getVideoUrl(video) {
    return { videoUrl: process.env.REACT_APP_IMAGE_URL + video.videoUrl };
  }
};

export default GroupChartView;
