import { TEMPLATE_TYPES } from '../constants/names';
import Physical from '../pages/CaseEventAnalysis/Report/Templates/Physical/Physical';
import TableView from '../pages/CaseEventAnalysis/Report/Templates/TableView/TableView';
import MultipleChoice from '../pages/CaseEventAnalysis/Report/Templates/MultipleChoice/MultipleChoice';
import Question2 from '../pages/CaseEventAnalysis/QuestionsAnswers/components/Question2/Question2';
import InterviewChart from '../pages/CaseEventAnalysis/Report/Templates/Chart/InterviewChart/InterviewChart';
import PieChartView from '../pages/CaseEventAnalysis/Report/Templates/Chart/PieChart/PieChartView';
import GroupChartView from '../pages/CaseEventAnalysis/Report/Templates/Chart/TableGroupChart/GroupChartView';
// import GroupChartView from '../pages/CaseEventAnalysis/Report/Templates/Chart/TableGroupChart/GroupChartView';

const {
  IMAGE_INTERACTIVE,
  MULTIPE_CHOICE,
  RANKING,
  TABLE_VIEW,
  INTERVIEW,
  TABLE_VIEW_GROUPS,
  MULTIPLE_CHOICE_GROUPS,
} = TEMPLATE_TYPES;

export const REPORT_TEMPLATE_CONFIGS = {
  [INTERVIEW]: {
    Component: TableView,
  },
  [TABLE_VIEW_GROUPS]: {
    Component: TableView,
  },
  [MULTIPLE_CHOICE_GROUPS]: {
    Component: TableView,
  },
  [TABLE_VIEW]: {
    Component: MultipleChoice,
  },
  [MULTIPE_CHOICE]: {
    Component: MultipleChoice,
  },
  [IMAGE_INTERACTIVE]: {
    Component: Physical,
  },
  [RANKING]: {
    Component: Question2,
  },
};

export const CHART_TEMPLATE_CONFIGS = {
  [INTERVIEW]: {
    Component: InterviewChart,
  },
  [TABLE_VIEW_GROUPS]: {
    Component: GroupChartView,
  },
  [MULTIPLE_CHOICE_GROUPS]: {
    Component: GroupChartView,
  },
  [IMAGE_INTERACTIVE]: {
    Component: GroupChartView,
  },
  [TABLE_VIEW]: {
    Component: GroupChartView,
  },
  [MULTIPE_CHOICE]: {
    Component: GroupChartView,
  },
  [RANKING]: {
    Component: PieChartView,
  },
};

export const TABLE_TEMPLATE_CONFIGS = {
  [INTERVIEW]: {
    Component: TableView,
  },
  [TABLE_VIEW_GROUPS]: {
    Component: TableView,
  },
  [MULTIPLE_CHOICE_GROUPS]: {
    Component: TableView,
  },
  [TABLE_VIEW]: {
    Component: MultipleChoice,
  },
  [MULTIPE_CHOICE]: {
    Component: MultipleChoice,
  },
  [RANKING]: {
    Component: Question2,
  },
  [IMAGE_INTERACTIVE]: {
    Component: TableView,
  },
};

export const REPORT_TEMPLATE_VIEW = {
  [INTERVIEW]: {
    View: [ 'Chart', 'Table' ],
  },
  [TABLE_VIEW_GROUPS]: {
    View: [ 'Chart', 'Table' ],
  },
  [MULTIPLE_CHOICE_GROUPS]: {
    View: [ 'Chart', 'Table' ],
  },
  [TABLE_VIEW]: {
    View: [ 'Chart', 'Table' ],
  },
  [MULTIPE_CHOICE]: {
    View: [ 'Chart', 'Table' ],
  },
  [IMAGE_INTERACTIVE]: {
    View: 'Table',
  },
  [RANKING]: {
    View: [ 'Chart', 'Table' ],
  },
};

export const RANK_NUMBER_TEXT = {
  1: '',
  2: 'SECOND',
  3: 'THIRD',
  4: 'FOURTH',
  5: 'FIFTH',
  6: 'SIXTH',
  7: 'SEVENTH',
  8: 'EIGHTH',
  9: 'NINTH',
  10: 'TENTH',
  11: 'ELEVENTH',
  12: 'TWELVETH',
  13: 'THIRTEENTH',
  14: 'FOURTEENTH',
  15: 'FIFTEENTH',
};
