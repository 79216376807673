import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import s from './LineClampItem.module.scss';

const LineClampItem = ({ content }) => {
  const [ expanded, setExpanded ] = useState(false);
  const [ requiresClamp, setRequiresClamp ] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      setRequiresClamp(contentElement.scrollHeight > contentElement.clientHeight);
    }
  }, []);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <div>
      <span
        ref={contentRef}
        className={classNames(s.lineClamp, {
          [s.lineExpand]: expanded,
        })}>
        {content.replace('***', '').trim() || '-'}
      </span>
      {requiresClamp && <span onClick={toggleExpand} className={s.expandButton}>
        {!expanded ? 'more' : 'less'}
      </span>}
    </div>
  );
};
export default LineClampItem;
