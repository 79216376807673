import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from '../../AccessForms.module.scss';
import { Button } from '../../../../../../components/UI';
import { updateAccessUserThunk } from '../../../../../../redux/thunks/accessThunk';
import copyIcon from '../../../../../../assets/nImages/copy_icon.svg';
import { setAlert } from '../../../../../../redux/slices/alertSlice';
import { ALERT_TYPES } from '../../../../../../constants/names';

const initalState = {
  username: '',
  password: '',
  id: '',
};

const AccessUpdate = () => {
  const dispatch = useDispatch();
  const { activeUser, isUpdateLoading } = useSelector(state => state.access);
  const [ localActiveModerator, setLocalActiveModerator ] = useState(initalState);
  const [ isEditActive, setEditActive ] = useState(false);

  const originalState = {
    username: activeUser.username,
    password: activeUser.password,
    id: activeUser.id,
  };

  useEffect(() => {
    if (activeUser) {
      setLocalActiveModerator(activeUser);
    }
  }, [ activeUser ]);

  const formHandler = (e) => {
    const { name, value } = e.target;
    setLocalActiveModerator(prev => ({
      ...prev, [name]: value,
    }));
  };

  const onUpdate = () => {
    dispatch(updateAccessUserThunk(localActiveModerator));
    setEditActive(false);
  };

  const onUsernameCopy = () => {
    dispatch(setAlert({ message: 'Copied!', type: ALERT_TYPES.SUCCESS, timer: 1500 }));
    navigator.clipboard.writeText(localActiveModerator.username);
  };

  const onPasswordCopy = () => {
    dispatch(setAlert({ message: 'Copied!', type: ALERT_TYPES.SUCCESS, timer: 1500 }));
    navigator.clipboard.writeText(localActiveModerator.password);
  };

  return (
    <div className={classNames(s.formItem, s.formItemUpdate)}>
      {isEditActive && (
        <div className={s.formItemMain}>
          <div>
            <div className={s.formInputWrapper}>
              <input
                disabled={!activeUser}
                value={localActiveModerator.username}
                name='username'
                onChange={formHandler}
                autocomplete='off'
              />
              <img
                src={copyIcon}
                className={s.accessCopyIcon}
                title='Copy Username'
                alt='Copy'
                onClick={onUsernameCopy}
              />
            </div>
          </div>
          <div>
            <div className={s.formInputWrapper}>
              <input
                disabled={!activeUser}
                value={localActiveModerator.password}
                name='password'
                onChange={formHandler}
                autocomplete='off'
              />
              <img
                src={copyIcon}
                className={s.accessCopyIcon}
                title='Copy Password'
                alt='Copy'
                onClick={onPasswordCopy}
              />
            </div>
          </div>
        </div>
      )}
      {!isEditActive && (
        <div className={s.formItemMain}>
          <div className={s.formItemLabel}>
            <span>{localActiveModerator.username}</span>
            <img
              src={copyIcon}
              className={s.accessCopyIcon}
              title='Copy Username'
              alt='Copy'
              onClick={onUsernameCopy}
            />
          </div>
          <div className={s.formItemLabel}>
            <span>{localActiveModerator.password}</span>
            <img
              src={copyIcon}
              className={s.accessCopyIcon}
              title='Copy Password'
              alt='Copy'
              onClick={onPasswordCopy}
            />
          </div>
        </div>
      )}
      {!isEditActive && (
        <div className={classNames(s.formActions, s.formRightAlign)}>
          <div className={s.formActionGroup}>
            <Button
              variant='darkGrey'
              onClick={() => setEditActive(prev => !prev)}
              className={s.addButton}>
              Edit
            </Button>
          </div>
        </div>
      )}
      {isEditActive && (
        <div className={s.formActions}>
          <div className={s.formActionGroup}>
            <Button
              variant='light'
              onClick={() => setLocalActiveModerator(initalState)}
              className={s.clearButton}>
              Clear
            </Button>
          </div>
          <div className={s.formActionGroup}>
            <Button
              variant='light'
              onClick={() => {
                setLocalActiveModerator(originalState);
                setEditActive(false);
              }}
              className={s.clearButton}>
              Cancel
            </Button>
            <Button
              variant='darkGrey'
              loading={isUpdateLoading}
              onClick={onUpdate}
              disabled={!localActiveModerator.username || !localActiveModerator.password}
              className={s.addButton}>
              Update
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccessUpdate;
