import classNames from 'classnames';
import React, {
  useEffect, useRef, useState, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { resetFilteredEvents } from '../../redux/slices/filteredEventsSlice';
import { resetUser, selectIsAuth } from '../../redux/slices/userInfoSlice';
import s from './Menu.module.scss';
import MedQP_Logo from '../../assets/nImages/MEDQA_LOGO.svg';
import { CHART_TEMPLATE_CONFIGS, TABLE_TEMPLATE_CONFIGS } from '../../configs/report.configs';
import ReportView from '../../pages/CaseEventAnalysis/Report/ReportView';
import { setActiveReportStats } from '../../redux/slices/reportAnalysSlice';

const Menu = ({ validRoutes }) => {
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const [ activeTab, setActiveTab ] = useState(null);
  const [ activeMenuItems, setActiveMenuItems ] = useState([ 'Events', 'Patient Cases', 'Overall Analysis', 'Case/Event Analysis' ]);
  const activeNavRef = useRef(null);
  const { analys } = useSelector(state => state.reportAnalys);

  useMemo(() => {
    if (!analys?.report) return [];
    validRoutes.map((route) => {
      if (route.name === 'Case/Event Analysis') {
        route['childs'] = getReportRoutes(analys.report);
      }
      return true;
    });
  }, [ analys ]);

  const getTabIcon = (tab, key = 'icon') => {
    if (!tab && !tab?.icon) return '';
    const icon = tab?.icon;
    return `${process.env.REACT_APP_IMAGE_URL}images/${icon}`;
  };

  const onDropdownHandler = (name) => {
    setActiveTab(name);
    if (activeMenuItems.includes(name)) {
      return setActiveMenuItems(activeMenuItems.filter(item => item !== name));
    }
    return setActiveMenuItems(prev => [ ...prev, name ]);
  };

  const signOut = () => {
    localStorage.removeItem('token');
    dispatch(resetUser());
    dispatch(resetFilteredEvents());
  };

  useEffect(() => {
    activeNavRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [ activeTab ]);

  return (
    <aside className={s.menu}>
      <div className={s.menuHolder}>
        <div className={s.headerLogo__icon}>
          <img className={s.headerLogo__svg} src={MedQP_Logo} alt="MedQP" />
        </div>
      </div>
      <section className={s.menuContainer}>
        <div className={s.menuNavbar}>
          {isAuth && validRoutes.map((route, i) => {
            if (!route.multi) {
              return (
                <div key={route.name} className={s.menuTitle}>
                  <NavLink activeClassName={s.activeItem} to={route.path} className={s.menuItem}>
                    {route.name}
                  </NavLink>
                </div>
              );
            }
            return (
              <div
                key={route.name}
                className={s.dropdown}>
                <div
                  onClick={() => onDropdownHandler(route.name)}
                  className={classNames(s.menuItem, s.dropdownTitle)}>
                  <div>{route.name}</div>
                  <div className={classNames(s.arrow,
                    { [s.arrowActive]: activeMenuItems.includes(route.name) })} />
                </div>
                {activeMenuItems.includes(route.name) && (
                  <div className={s.multiItems}>
                    {route.childs.map((child, idx) => (
                      (child.childs?.length === 0 || child.childs === undefined) ? (<NavLink
                        key={child.name}
                        className={classNames(
                          s.menuItem,
                          s.dropdownMenuItem,
                        )}
                        activeClassName={s.activeItem}
                        // onClick={() => setActiveDropdown(null)}
                        to={route.path + child.path}>
                        {child.name}
                      </NavLink>)
                        : (<div key={child.name} className={s.customMenuContainer}>
                          <div
                            onClick={() => onDropdownHandler(child.name)}
                            className={classNames(s.menuItem, s.dropdownTitle)}>
                            <div className={s.menuBox}>
                              <img
                                alt={child.name}
                                className={s.menuImage}
                                src={getTabIcon(child)}
                                name={child.name} />
                              <span>{child.name}</span>
                            </div>
                            <div className={classNames(s.arrow,
                              { [s.arrowActive]: activeMenuItems.includes(child?.name) })} />
                          </div>
                          {activeMenuItems.includes(child.name)
                            && (<div
                              className={classNames(s.multiItems, s.menuSupraItem)}
                              ref={child.name === activeTab ? activeNavRef : null}>
                              {child.childs?.map((supraChild, supraIdx) => (
                                <NavLink
                                  key={supraChild.name + supraIdx}
                                  id={idx}
                                  className={classNames(s.menuItem, s.dropdownMenuItem)}
                                  activeClassName={s.activeItem}
                                  onClick={() => dispatch(setActiveReportStats([ idx, supraIdx + 1 ]))}
                                  to={route.path + supraChild.path}>
                                  {supraChild.name}
                                </NavLink>))}
                            </div>)}
                        </div>)))}
                  </div>
                )}
              </div>);
          })}
          <div className={s.menuGreenBubble} />
        </div>
        {isAuth && <div onClick={signOut} className={s.signOut} >
          <div>Sign out</div>
        </div>
        }
      </section>
    </aside>
  );
};

const getReportRoutes = (reports) => {
  const reportRoutes = [];
  Object.keys(reports).forEach((key, index) => {
    if (reports[key].question.type !== 'presentation') {
      const tableComponent = TABLE_TEMPLATE_CONFIGS[reports[key].question.type];
      const chartComponent = CHART_TEMPLATE_CONFIGS[reports[key].question.type];

      const childComponent = [];

      if (chartComponent) {
        childComponent.push({
          name: 'Chart',
          path: `/reportView/${index}`,
          type: reports[key].question.type,
          Component: ReportView,
        });
      }
      if (tableComponent) {
        childComponent.push({
          name: 'Table',
          path: `/reportView/${index}${index}`,
          type: reports[key].question.type,
          Component: ReportView,
        });
      }
      reportRoutes.push({
        name: key,
        path: `/reportView${
          reports[key].question.type === 'imageInteractive' ? `/${index}${index}` : '/:id'
        }`,
        icon: reports[key].question.icon,
        image: reports[key].question.image,
        type: reports[key].question.type,
        Component: ReportView,
        childs: childComponent,
      });
    }
  });
  return reportRoutes;
};

export default Menu;
