import { createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS } from '../../api/axios';
import { getErrorMessage } from '../../helpers/api';
import { setAlert } from '../slices/alertSlice';

// GET FILTERS OPTIONS
export const getReportAnalysThunk = createAsyncThunk(
  'reportAnalys/getReportAnalys',
  async (data, { dispatch, getState }) => {
    const response = await AXIOS.post('/analytics/report', data).catch((err) => {
      dispatch(setAlert({ message: getErrorMessage(err) }));
      throw Error(err);
    });
    return response.data;
  },
);

const getReportAnalysThunkPending = (state) => {
  state.isLoading = true;
};

const getReportAnalysThunkFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.fullReportAnalys = payload;
  if (payload?.length === 0 || payload?.length === 1) {
    state.analys = payload[0];
  } else {
    state.analys = payload[state.caseVisitNo];
  }
};

export const reportAnalysExtraReducers = (builder) => {
  builder
    .addCase(getReportAnalysThunk.pending, getReportAnalysThunkPending)
    .addCase(getReportAnalysThunk.fulfilled, getReportAnalysThunkFulfilled);
};
