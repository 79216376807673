import React, { useEffect, useState } from 'react';
import s from './Timepicker.module.scss';

const Timepicker = ({
  onTimeChange, time, showDayConfig = false,
}) => {
  const [ eventHour, setEventHourHandler ] = useState(Number(time.split(':')[0]));
  const [ eventMinute, setEventMinuteHandler ] = useState(Number(time.split(':')[1]));
  const [ eventDays, setEventDayHandler ] = useState(Number(time.split(':')[2]) || 0);

  const onDayChangeHandler = (e) => {
    const num = e.target.value;
    const re = /^[0-9\b]+$/;
    if ((num !== '' && re.test(num)) && num >= 0 && num <= 99) {
      setEventDayHandler(Number(num));
    }
  };

  const increaseDayHandler = () => {
    if (eventDays < 99) setEventDayHandler((+eventDays || 0) + 1);
  };

  const decreaseDayHandler = () => {
    if (+eventDays && +eventDays - 1 >= 0) {
      setEventDayHandler(+eventDays - 1);
    }
  };

  const onHourChangeHandler = (e) => {
    const num = e.target.value;
    const re = /^[0-9\b]+$/;
    if ((num !== '' && re.test(num)) && num >= 0 && num <= 23) {
      setEventHourHandler(Number(num));
    }
  };

  const increaseHourHandler = () => {
    if (eventHour < 23) setEventHourHandler((+eventHour || 0) + 1);
  };

  const decreaseHourHandler = () => {
    if (+eventHour && +eventHour - 1 >= 0) {
      setEventHourHandler(+eventHour - 1);
    }
  };

  const onMinuteChangeHandler = (e) => {
    const num = e.target.value;
    const re = /^[0-9\b]+$/;
    if ((num !== '' && re.test(num)) && num >= 0 && num <= 59) {
      setEventMinuteHandler(num);
    }
  };

  const increaseMinuteHandler = () => {
    if (eventMinute < 59) setEventMinuteHandler((+eventMinute || 0) + 1);
  };

  const decreaseMinuteHandler = () => {
    if (+eventMinute && +eventMinute - 1 >= 0) {
      setEventMinuteHandler(+eventMinute - 1);
    }
  };

  useEffect(() => {
    const formattedTime = eventDays !== 0
      ? `${eventHour}:${formattedMinutes}:${eventDays}` : `${eventHour}:${formattedMinutes}`;
    onTimeChange(formattedTime);
  }, [ eventMinute, eventHour, eventDays ]);

  const formattedMinutes = eventMinute < 10 ? `0${Number(eventMinute)}` : Number(eventMinute);

  return (
    <div className={s.root}>
      {showDayConfig && <div className={s.eventTimeHolder}>
        <div className={s.eventTimeInput}>
          <input value={eventDays} onChange={onDayChangeHandler} max={99} min={0} type='tel' />
        </div>
        <div className={s.plusMinus}>
          <div onClick={increaseDayHandler}>+</div>
          <div onClick={decreaseDayHandler}>-</div>
        </div>
      </div>}
      {showDayConfig && <div className={s.eventDaysLabel}>days</div>}
      <div className={s.eventTimeHolder}>
        <div className={s.eventTimeInput}>
          <input value={eventHour} onChange={onHourChangeHandler} max={23} min={0} type='tel' />
        </div>
        <div className={s.plusMinus}>
          <div onClick={increaseHourHandler}>+</div>
          <div onClick={decreaseHourHandler}>-</div>
        </div>
      </div>
      <div className={s.eventHoursLabel}>hours</div>
      <div className={s.eventTimeHolder}>
        <div className={s.eventTimeInput}>
          <input value={formattedMinutes} onChange={onMinuteChangeHandler} max={59} min={0} type='tel' />
        </div>
        <div className={s.plusMinus}>
          <div onClick={increaseMinuteHandler}>+</div>
          <div onClick={decreaseMinuteHandler}>-</div>
        </div>
      </div>
      <div className={s.eventTimeLabel}>mins</div>
    </div>
  );
};

export default Timepicker;
