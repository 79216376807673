import axios from 'axios';

export const AXIOS = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

AXIOS.interceptors.response.use(
  response => response.data,
  (error) => {
    if ((error.status || error.response?.status) === 401) {
      localStorage.removeItem('token');
    }

    return Promise.reject(error.response ? error.response.data : error);
  },
);
