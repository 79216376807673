import {
  useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import FilterCard from '../../../components/FilterCard/FilterCard';
import Heading from '../../../components/Heading/Heading';
import { REPORT_TEMPLATE_CONFIGS } from '../../../configs/report.configs';
import s from '../CaseEventAnalysis.module.scss';
import NoItem from '../../../components/NoItem/NoItem';
import { getReportAnalysThunk } from '../../../redux/thunks/reportAnalysThunk';
import { EVENT_KEYS, QUESTION_KEYS, TEMPLATE_TYPES } from '../../../constants/names';
import { resetReportAnalys, setReportCaseVisit } from '../../../redux/slices/reportAnalysSlice';
import { selectIsModerator } from '../../../redux/slices/userInfoSlice';
import { selectModeratorActiveEvent } from '../../../redux/slices/moderatorSlice';
import InterviewChart from './Templates/Chart/InterviewChart/InterviewChart';
import GroupChartView from './Templates/Chart/TableGroupChart/GroupChartView';
import PieChartView from './Templates/Chart/PieChart/PieChartView';
import PhysicalChartView from './Templates/Chart/PhysicalChart/PhysicalChartView';
import MultipleChoiceChart from './Templates/Chart/MultipleChoice/MultipleChoiceChart';
import { getNumberText } from '../../../helpers/utils';

const { CHAPTER_TYPE, CHAPTER_NAME } = QUESTION_KEYS;

const ReportView = () => {
  const dispatch = useDispatch();
  const isModerator = useSelector(selectIsModerator);
  const {
    analys, isLoading, fullReportAnalys, caseVisitNo, activeReportIndex, activeReportType,
  } = useSelector(state => state.reportAnalys);
  const [ isOnlyTop, setIsOnlyTop ] = useState(false);
  const { eventsIds } = useSelector(state => state.filteredEvents.eventsData);
  const filterCases = useSelector(state => state.filteredEvents.filters[EVENT_KEYS.CASE_LIST]);
  const moderatorEvent = useSelector(selectModeratorActiveEvent);

  const allReports = useMemo(() => {
    if (!analys?.report) return [];
    return Object.values(analys.report);
  }, [ analys ]);
  const activeTabData = allReports.length ? allReports[activeReportIndex] : null;
  const activeTabConfigs = activeTabData
    ? REPORT_TEMPLATE_CONFIGS[activeTabData.question[CHAPTER_TYPE]] : null;

  const isRanking = activeTabData?.question[CHAPTER_TYPE] === TEMPLATE_TYPES.RANKING;
  const headTitle = `Case/Event Analysis - ${activeTabData?.question[CHAPTER_NAME] || 'Report'}`;

  useEffect(() => {
    if (eventsIds.length || (moderatorEvent && isModerator)) {
      const data = {
        events: !isModerator ? eventsIds : [ moderatorEvent.id ],
      };
      if (!isModerator) {
        data.caseId = filterCases[0];
      }
      dispatch(getReportAnalysThunk(data));
    }
    return () => {
      dispatch(resetReportAnalys());
    };
  }, [ eventsIds, moderatorEvent ]);

  return (
    <div className={s.wrapper}>
      <Heading reset={resetReportAnalys} headTitle={headTitle}>
        <div className={s.reportFilterCard}>
          <FilterCard
            displayOnlyTop={analys && analys.doctors && !isRanking}
            isOnlyTop={isOnlyTop}
            setIsOnlyTop={setIsOnlyTop}
            isModerator={isModerator}
          />
        </div>
      </Heading>
      <section className='card_container_outer'>
        <section className='card_container_inner'>
          <section className='card_holder'>
            <div className='cards'>
              <div className={s.caseEventBody}>
                <div className={s.contentBoard}>
                  {!isLoading && (analys && analys.doctors
                    ? <>
                      <div className={s.reportBody}>
                        {isModerator && <div className={s.patientVisitDetails}>
                          {fullReportAnalys && fullReportAnalys.map((e, idx) => (
                            <div
                              key={`${idx}visit`}
                              onClick={() => dispatch(setReportCaseVisit(idx))}
                              className={classNames(s.patientVisitBtn, {
                                [s.pvActiveVisit]: idx === caseVisitNo,
                              })}
                            >
                              {`${getNumberText(idx)} Visit`}
                            </div>
                          ))}
                        </div>}
                        <div className={classNames(s.currentPage, s.reportPage, {
                          [s.moderatorView]: isModerator,
                        })}>
                          {activeTabConfigs
                            && activeTabData.question['type'] === 'interview'
                            && activeReportType === 1
                            && <InterviewChart
                              isOnlyTop={isOnlyTop}
                              data={isRanking ? activeTabData.answers : activeTabData}
                              title={activeTabData.question[CHAPTER_NAME]}
                          />}
                          {activeTabConfigs
                            && activeReportType === 2
                          && <activeTabConfigs.Component
                            isOnlyTop={isOnlyTop}
                            data={isRanking ? activeTabData.answers : activeTabData}
                            title={activeTabData.question[CHAPTER_NAME]} />}
                          {activeTabConfigs
                            && activeTabData.question['type'] === 'ranking'
                            && activeReportType === 1
                            && <PieChartView data={activeTabData} />}
                          {activeTabConfigs
                            && activeTabData.question['type'] === 'imageInteractive'
                            && activeReportType === 1
                            && <PhysicalChartView isOnlyTop={isOnlyTop} data={activeTabData} />}
                          {activeTabConfigs
                            && (activeTabData.question['type'] === 'groups' || activeTabData.question['type'] === 'mcGroups'
                            || activeTabData.question['type'] === 'noGroups')
                            && activeReportType === 1
                            && <GroupChartView
                              key={'groupChart'}
                              data={activeTabData}
                              comments={activeTabData.comments}
                              isOnlyTop={isOnlyTop}
                            />}
                          {activeTabConfigs
                            && activeTabData.question['type'] === 'multipleChoice'
                            && activeReportType === 1
                            && <MultipleChoiceChart
                              data={activeTabData}
                              comments={activeTabData.comments}
                              isOnlyTop={isOnlyTop}
                              />}
                        </div>
                      </div>
                    </>
                    : <div className={s.noEvent}><NoItem analys /></div>)
                  }
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default ReportView;
