import { useMemo } from 'react';
import { RANK_NUMBER_TEXT } from '../../../../../../configs/report.configs';
import s from './InterviewChart.module.scss';
import NoItem from '../../../../../../components/NoItem/NoItem';

const InterviewChart = ({ data, isOnlyTop }) => {
  const { charts } = data;
  const keys = useMemo(
    () => (
      isOnlyTop
        ? Object.keys(charts)
          .sort((a, b) => b - a)
          .slice(0, 10)
        : Object.keys(charts).sort((a, b) => b - a)),
    [ charts, isOnlyTop ],
  );

  return (
    <div className={s.container}>
      {keys && keys.map((count, i) => (
        <div className={s.section} key={count + i}>
          <div className={s.header}>
            <div className={s.headCount}>Count: {count}</div>
            <div className={s.headLabel}>{RANK_NUMBER_TEXT[i + 1]} MOST FREQUENTLY ASKED QUESTIONS</div>
          </div>
          <div className={s.body}>
            {Object.keys(charts[count]).map((category, ci) => (
              <section key={category} className={s.catGroup}>
                <div className={s.catHeader}>{category}</div>
                <ul className={s.questionAnswerGroup}>
                  {charts[count][category].map((item, idx) => (
                    <li className={s.questionAnswer} key={idx + i}>
                      <span className={s.question}>{item.Question}</span>
                      <span className={s.answer}>({item.Answer})</span>
                    </li>
                  ))}
                </ul>
              </section>
            ))}
          </div>
        </div>
      ))}
      {!keys.length && <div className={s.noEvent}><NoItem title={'Chart'} /></div>}
    </div>
  );
};

export default InterviewChart;
