import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { Button, Input } from '../../components/UI';
import { loginUserThunk } from '../../redux/thunks/userInfoThunk';
import s from './Login.module.scss';
import MedQP_Logo from '../../assets/nImages/MEDQA_LOGO.svg';

const Login = () => {
  const dispatch = useDispatch();
  const { isAuthLoading } = useSelector(state => state.userInfo);
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ isPasswordVisible, setIsPasswordVisible ] = useState(false);

  const buttonDisabled = !username.trim() || !password.trim();

  const loginUser = useCallback(
    debounce(() => dispatch(loginUserThunk({ username, password })), 500),
    [ username, password ],
  );

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (buttonDisabled) return;
    loginUser();
  };

  return (
    <div className={s.root}>
      <section className={s.loginContainer}>
        <div className={s.formBoard}>
          <div className={s.loginImage}>
            <img src={MedQP_Logo} alt='MedQP' />
          </div>
          <form className={s.loginForm}>
            <div className={s.username}>
              <div className={s.inputLabel}>Username</div>
              <Input value={username} onChange={e => setUsername(e.target.value)} />
            </div>
            <div className={s.password}>
              <div className={s.inputLabel}>Password</div>
              <Input
                eye
                eyeActive={isPasswordVisible}
                onEyeClick={() => setIsPasswordVisible(prev => !prev)}
                type={isPasswordVisible ? 'text' : 'password'}
                value={password}
                onChange={e => setPassword(e.target.value)} />
            </div>
            <div className={s.buttonWrapper}>
              <Button
                loading={isAuthLoading}
                disabled={buttonDisabled}
                onClick={onSubmitHandler}>
                SIGN IN
              </Button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Login;
