/* eslint-disable react-hooks/rules-of-hooks */
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import s from './Dropdown.module.scss';

const Dropdown = ({
  active, value, className, rootClass, children, toggle, onlyModal, isView, dropPos = { xPos: 0, parentWidth: 0, parentBottom: 0 }, ...props
}) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => toggle(false), active);
  const rootStyle = dropPos ? { left: (dropPos?.xPos - dropPos?.parentWidth + 10), top: dropPos?.parentBottom + 10 } : {};
  useEffect(() => {
    if (active) {
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [ active ]);

  return (
    <div
      ref={ref}
      className={classNames(s.dropdownRoot, { [rootClass]: rootClass })}
      style={rootStyle}>
      {!onlyModal && (
        <div
          onClick={() => toggle(prev => !prev)}
          className={classNames(s.dropdown, { [s.dropdownActive]: active, [className]: className })}
          {...props}>
          <div>{value}</div>
          {!isView && (
            <div className={s.arrowWrapper}>
              <div className={s.arrow} />
            </div>
          )}
        </div>
      )}
      {active && children}
    </div>
  );
};

Dropdown.Modal = ({ children, className, dropPos }) => {
  const modalStyle = dropPos ? { width: dropPos?.parentWidth } : {};
  return (
    <div
      style={modalStyle}
      className={classNames(s.dropdownModal, {
        [className]: className,
      })}>
      {children}
    </div>
  );
};

export default Dropdown;
