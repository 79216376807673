import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterCard from '../../../components/FilterCard/FilterCard';
import Heading from '../../../components/Heading/Heading';
import s from '../CaseEventAnalysis.module.scss';
import NoItem from '../../../components/NoItem/NoItem';
import { getReportAnalysThunk } from '../../../redux/thunks/reportAnalysThunk';
import { EVENT_KEYS } from '../../../constants/names';
import { resetReportAnalys } from '../../../redux/slices/reportAnalysSlice';
import { selectIsModerator } from '../../../redux/slices/userInfoSlice';
import { selectModeratorActiveEvent } from '../../../redux/slices/moderatorSlice';

const Report = () => {
  const dispatch = useDispatch();
  const isModerator = useSelector(selectIsModerator);
  const { eventsIds } = useSelector(state => state.filteredEvents.eventsData);
  const filterCases = useSelector(state => state.filteredEvents.filters[EVENT_KEYS.CASE_LIST]);
  const moderatorEvent = useSelector(selectModeratorActiveEvent);

  useEffect(() => {
    if (eventsIds.length || (moderatorEvent && isModerator)) {
      const data = {
        events: !isModerator ? eventsIds : [ moderatorEvent.id ],
      };
      if (!isModerator) {
        data.caseId = filterCases[0];
      }
      dispatch(getReportAnalysThunk(data));
    }
    return () => {
      dispatch(resetReportAnalys());
    };
  }, [ eventsIds, moderatorEvent ]);

  return (
    <div className={s.wrapper}>
      <Heading reset={resetReportAnalys}>
        {!isModerator && <FilterCard />}
      </Heading>
      <section className='card_container_outer'>
        <section className='card_container_inner'>
          <section className='card_holder'>
            <div className='cards'>
              <div className={s.caseEventBody}>
                <div className={s.contentBoard}>
                  <div className={s.noEvent}><NoItem analys /></div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default Report;
