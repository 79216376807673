import s from './Progress.module.scss';

const Progress = ({ percent = 0 }) => (
  <div className={s.progressBar}>
    <div className={s.activeProgress} style={{ width: `${percent}%` }}>
      {/* {(percent > 0 && percent !== 100) && <div className={s.activeProgressContent}>
        {percent}
      </div>} */}
    </div>
  </div>
);

export default Progress;
