import { createSlice } from '@reduxjs/toolkit';
import { reportAnalysExtraReducers } from '../thunks/reportAnalysThunk';

const initialState = {
  analys: null,
  isLoading: false,
  fullReportAnalys: null,
  caseVisitNo: 0,
  activeReportIndex: 0,
  activeReportType: 1,
};

export const reportAnalysSlice = createSlice({
  name: 'reportAnalys',
  initialState,
  reducers: {
    addFullReportAnalys: (state, { payload }) => { state.fullReportAnalys = payload; },
    addReportAnalys: (state, { payload }) => { state.analys = payload; },
    resetReportAnalys: () => initialState,
    setReportLoading: (state, { payload }) => { state.isLoading = payload; },
    setReportCaseVisit: (state, { payload }) => {
      state.caseVisitNo = payload;
      state.analys = state.fullReportAnalys[payload];
    },
    setActiveReportStats: (state, { payload }) => {
      state.activeReportIndex = payload[0];
      state.activeReportType = payload[1];
    },
  },
  extraReducers: reportAnalysExtraReducers,
});

// ACTIONS
export const {
  addReportAnalys,
  resetReportAnalys,
  setReportLoading,
  setReportCaseVisit,
  addFullReportAnalys,
  setActiveReportStats,
} = reportAnalysSlice.actions;

// SELECTORS
export const selectReportAnalys = state => state.reportAnalys.events;

export default reportAnalysSlice.reducer;
