import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Heading from '../../../components/Heading/Heading';
import FilterCard from '../../../components/FilterCard/FilterCard';
import StatisticItem from './components/StatisticItem/StatisticItem';
import s from './GeneralAnalysis.module.scss';
import { getGeneralAnalysThunk } from '../../../redux/thunks/generalAnalysThunk';
import { resetGeneralAnalys } from '../../../redux/slices/generalAnalysSlice';
import { selectIsModerator } from '../../../redux/slices/userInfoSlice';
import { selectModeratorActiveEvent } from '../../../redux/slices/moderatorSlice';
import { EVENT_KEYS } from '../../../constants/names';

const generalStatistics = [
  { title: 'Events', key: 'events' },
  { title: 'Patient Cases', key: 'cases' },
];

const partisipationStatistics = [
  {
    title: 'Total',
    key: 'total',
    description: <span className={s.description}><span>No. of participants who</span><span>clicked on the event link</span></span>,
  },
  {
    title: 'Started',
    key: 'started',
    description: <span className={s.description}><span>No. of participants who</span><span>started the patient case</span></span>,
  },
  { title: 'Completed', key: 'completed' },
  { title: 'Abandoned', key: 'abandoned' },
];

const GeneralAnalysis = () => {
  const dispatch = useDispatch();
  const isModerator = useSelector(selectIsModerator);
  const { eventsIds } = useSelector(state => state.filteredEvents.eventsData);
  const filterCases = useSelector(state => state.filteredEvents.filters[EVENT_KEYS.CASE_LIST]);
  const moderatorEvent = useSelector(selectModeratorActiveEvent);

  useEffect(() => {
    if (eventsIds.length || (moderatorEvent && isModerator)) {
      const data = {
        events: !isModerator ? eventsIds : [ moderatorEvent.id ],
      };
      if (!isModerator) {
        data.caseId = filterCases[0];
      }
      dispatch(getGeneralAnalysThunk(data));
    }
    return () => {
      dispatch(resetGeneralAnalys());
    };
  }, [ eventsIds, moderatorEvent ]);

  return (
    <div className={s.root}>
      <Heading reset={resetGeneralAnalys}>
        {!isModerator && <FilterCard />}
      </Heading>
      <section className='card_container_outer'>
        <section className='card_container_inner'>
          <section className='card_holder'>
            <div className='cards'>
              <div className={s.statisticsWrapper}>
                <div className={s.statistics}>
                  <div className={s.statisticRow}>
                    <div className={s.statisticTitle}>General</div>
                    <div className={s.statisticItems}>
                      {generalStatistics.map(item => (
                        <StatisticItem key={item.title} info={item} />
                      ))}
                    </div>
                  </div>
                  <div className={s.statisticRow}>
                    <div className={s.statisticTitle}>Participation</div>
                    <div className={s.statisticItems}>
                      {partisipationStatistics.map(item => (
                        <StatisticItem
                          key={item.title}
                          info={item}
                          description={item.description}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default GeneralAnalysis;
