import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PhotoPopup from '../../../../../components/PhotoPopup/PhotoPopup';
import { Table } from '../../../../../components/UI';
import CheckboxPure from '../../../../../components/UI/Checkbox/CheckboxPure';
import Dropdown from '../../../../../components/UI/Dropdown/Dropdown';
import { getTop10Items } from '../../../../../helpers/utils';
import s from '../../Report.module.scss';

const headerWidths = [ '18%', '16%', '14%', '12%', '18%', '11%', '11%' ];

const Physical = ({ data, isOnlyTop }) => {
  const [ showPhoto, setShowPhoto ] = useState(null);
  const files = useSelector(state => state.reportAnalys.analys.files);
  const { answers: { physical, additional }, answersCategories } = data;
  const allData = useMemo(() => {
    const items = [];
    Object.values({ ...physical, ...additional }).forEach((item) => {
      item.forEach(el => items.push(el));
    });
    return items;
  }, [ physical, additional ]);

  const tableKeys = answersCategories.physical;

  const keys = Object.keys({ ...physical, ...additional });
  const [ isDropdownActive, setIsDropdownActive ] = useState(false);
  const [ checkedKeys, setCheckedKeys ] = useState(keys);
  const [ dropdownPos, setDropdownPos ] = useState({
    xPos: 0,
    parentWidth: 0,
    parentBottom: 0,
  });

  const isAllChecked = keys.length === checkedKeys.length;
  const filteredData = useMemo(() => (
    allData.filter(item => checkedKeys.includes(item[tableKeys[0]]))
  ), [ allData, checkedKeys ]);

  const finalData = useMemo(() => (
    isOnlyTop ? getTop10Items(filteredData) : filteredData
  ), [ filteredData, isOnlyTop ]);

  const onSelectAll = () => {
    if (isAllChecked) {
      setCheckedKeys([]);
    } else {
      setCheckedKeys(keys);
    }
  };

  const onDropItemClick = (key) => {
    if (checkedKeys.includes(key)) {
      setCheckedKeys(prev => prev.filter(item => item !== key));
    } else {
      setCheckedKeys(prev => [ ...prev, key ]);
    }
  };

  const handleDropdownClick = (event) => {
    setIsDropdownActive(prev => !prev);
    setDropdownPos({
      xPos: event.clientX,
      yPos: event.clientY,
      parentWidth: event.currentTarget.parentNode.offsetWidth,
      parentBottom: event.currentTarget.parentNode.getBoundingClientRect().bottom,
    });
  };

  return (
    <div className={s.physicalReportContainer}>
      <Table>
        <Table.Header>
          {tableKeys.map((header, i) => (
            <Table.HeaderCell
              key={header}
              className={s.tableHeader}
              width={headerWidths[i]}>
              <div className={s.headerCell}>
                <div>{header}</div>
                { i === 0 && <div
                  onClick={handleDropdownClick}
                  className={s.dropdownIconWrapper}>
                  <div
                    className={
                    classNames(s.dropdownIcon, { [s.dropdownIconActive]: isDropdownActive })} />
                </div>}
              </div>
            </Table.HeaderCell>
          ))}
          <Dropdown
            rootClass={s.dropdown}
            toggle={setIsDropdownActive}
            active={isDropdownActive}
            dropPos={dropdownPos}
            onlyModal>
            <Dropdown.Modal className={s.dropdownModal} dropPos={dropdownPos}>
              <div className={s.dropdownList}>
                {!!keys.length && (
                <div onClick={onSelectAll} className={classNames(s.dropSelectAll, s.dropdownItem)}>
                  <div>Select All</div>
                  <div><CheckboxPure checked={isAllChecked} /></div>
                </div>
                )}
                {keys.map(key => (
                  <div onClick={() => onDropItemClick(key)} key={key} className={s.dropdownItem}>
                    <div>{key}</div>
                    <div><CheckboxPure
                      checked={checkedKeys.includes(key)}
                       /></div>
                  </div>
                ))}
              </div>
            </Dropdown.Modal>
          </Dropdown>
        </Table.Header>
        <Table.Body>
          {finalData.map(row => (
            <Table.Row key={row.id}>
              {tableKeys.map((item, i) => {
                if (item === 'IMAGE') {
                  const image = row[item];
                  return <Table.Cell
                    key={item}
                    width={headerWidths[i]}
                    onClick={() => image && image && setShowPhoto(getPhotoPath(image))}
                    className={classNames(s.tableRow, { [s.pointer]: image })}>

                    <div>{image ? 'View' : '-'}</div>
                  </Table.Cell>;
                }
                return <Table.Cell
                  className={s.tableRow}
                  width={headerWidths[i]}
                  key={item}>
                  <div>{![ null, undefined ].includes(row[item]) ? String(row[item]).split('\n').map((paragraph, idx) => (
                    <p key={idx} className='paragraph'>{paragraph}</p>
                  )) : '-'}</div>
                </Table.Cell>;
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {showPhoto
        && <PhotoPopup img={showPhoto} onClose={() => setShowPhoto(null)} />
      }
    </div>
  );
  function getPhotoPath(name) {
    const file = files.find(item => item.name === name);
    return file ? process.env.REACT_APP_IMAGE_URL + file.mozJpeg : '';
  }
};
export default Physical;
