import classNames from 'classnames';
import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Progress } from '../../../../../../components/UI';
import s from './PhysicalChartView.module.scss';
import LineClampItem from '../../../../../../components/UI/LineClampItem/LineClampItem';
import PhotoPopup from '../../../../../../components/PhotoPopup/PhotoPopup';
import CarouselPopup from '../../../../../../components/CarouselPopup/CarouselPopup';
import { getTop10Items } from '../../../../../../helpers/utils';

const PhysicalChartView = ({ data, isOnlyTop }) => {
  const [ showPhoto, setShowPhoto ] = useState(null);
  const [ showPhotos, setShowPhotos ] = useState(null);
  const files = useSelector(state => state.reportAnalys.analys.files);
  const getPhotoPaths = name => files.find(item => item.name === name) || null;
  const {
    charts, answersCategories, question,
  } = data;
  const chapterType = question.type;
  const finalCharts = useMemo(() => (
    isOnlyTop ? getTop10Items(charts) : charts
  ), [ charts, isOnlyTop ]);
  const additionalAnswerCategories = answersCategories['additional'];

  return (
    <div className={s.physicalChart1Root}>
      <div className={s.physicalChart1Content}>
        {finalCharts
          && finalCharts.map((item, i) => (
            <div key={i} className={s.physicalChartItem}>
              {<div className={s.physicalChartTitle}>
                <div className={s.physicalChartTitleHeader}>
                  {item['Physical Exam']
                    || item['Additional Exam']}
                  {(item['Front/Back'] !== 'Both or N/A' && item['Front/Back'] !== 'N/A')
                    ? ` - ${item['Front/Back']}` : ''}
                </div>
                <div className={classNames(s.physicalChartTitleBody)}>
                  {additionalAnswerCategories[2] !== 'IMAGE' && item[additionalAnswerCategories[2]]
                    && <LineClampItem content={item[additionalAnswerCategories[4]]} />}
                </div>
                {/* <div className={s.physicalChartTitleFooter}>
                  {additionalAnswerCategories[3] !== 'IMAGE' && item[additionalAnswerCategories[3]]
                    && <LineClampItem content={item[additionalAnswerCategories[4]]} />}
                </div> */}
              </div>}
              <div className={s.physicalChartInfo}>
                <div className={s.progressItemInfo}>
                  <Progress percent={item.percentage || 0} />
                  {chapterType !== 'noGroups'
                    && <div className={s.progressItemLabel}>{item[additionalAnswerCategories[0]]}</div>}
                </div>
                <div className={s.progressItemPercent}>
                  {item.count} - ({item.percentage || 0}%)
                </div>
                {additionalAnswerCategories['additional']?.map((category) => {
                  if (category === 'IMAGE') {
                    const image = item[category];
                    const isImage = item[category].split(',').length === 1;
                    const isImages = item[category].split(',').length > 1;
                    let imageFile;
                    let imageFiles;
                    if (isImage) {
                      imageFile = getPhotoPath(item[category]);
                    }
                    if (isImages) {
                      imageFiles = item[category].split(',').map(im => im.trim()).map(im => getPhotoPaths(im));
                    }
                    return (
                      <div key={item} className={s.physicalChartImage}>
                        {isImage && (
                          <div className={classNames({ [s.imageView]: image })}>
                            {image && (
                              <div
                                onClick={() => image && setShowPhoto(imageFile)}>
                                Image
                              </div>
                            )}
                          </div>
                        )}
                        {isImages && (
                          <div className={classNames({ [s.imageView]: image })}>
                            {imageFiles && (
                              <div onClick={() => imageFiles && setShowPhotos(imageFiles)}>
                                Images
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  }
                  return true;
                })}
              </div>
            </div>
          ))}
      </div>
      {showPhoto
        && <PhotoPopup img={showPhoto} onClose={() => setShowPhoto(null)} />
      }
      {showPhotos
        && <CarouselPopup files={showPhotos} onClose={() => setShowPhotos(null)} />
      }
    </div>
  );
  function getPhotoPath(name) {
    const file = files.find(item => item.name === name);
    return file ? process.env.REACT_APP_IMAGE_URL + file.mozJpeg : '';
  }
};

export default PhysicalChartView;
