import { createSlice } from '@reduxjs/toolkit';
import { satisfactionExtraReducers } from '../thunks/satisfactionThunk';

const initialState = {
  analys: null,
  isLoading: false,
  fullAnalys: null,
  patientVisitNo: 0,
};

export const satisfactionSlice = createSlice({
  name: 'satisfactionAnalys',
  initialState,
  reducers: {
    addSatisfactionAnalys: (state, { payload }) => {
      state.analys = payload[0];
      state.fullAnalys = payload;
    },
    resetSatisfactionAnalys: () => initialState,
    setSatisfactionLoading: (state, { payload }) => { state.isLoading = payload; },
    setPatientVisitNo: (state, { payload }) => {
      state.analys = state.fullAnalys[payload];
      state.patientVisitNo = payload;
    },
  },
  extraReducers: satisfactionExtraReducers,
});

// ACTIONS
export const {
  addSatisfactionAnalys,
  resetSatisfactionAnalys,
  setSatisfactionLoading,
  setPatientVisitNo,
} = satisfactionSlice.actions;

// SELECTORS
export const selectSatisfactionAnalys = state => state.satisfactionAnalys.events;

export default satisfactionSlice.reducer;
