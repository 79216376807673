import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EVENT_TYPE_NAMES } from '../../constants/names';
import { toggleFilterModal } from '../../redux/slices/filteredEventsSlice';
import { Card } from '../UI';
import s from './FilterCard.module.scss';
import FilterCardLoader from './FilterCardLoader';

const FilterCard = ({
  displayOnlyTop = false, isOnlyTop = false, setIsOnlyTop, isModerator,
}) => {
  const dispatch = useDispatch();
  const [ isActive, setIsActive ] = useState(false);
  const {
    optionsLoading, eventsData,
  } = useSelector(state => state.filteredEvents);
  const { events } = eventsData;
  const openModal = () => {
    dispatch(toggleFilterModal(true));
  };

  return (
    <div className={s.filterContainer}>
      {!optionsLoading
        ? <Card
            onClick={() => setIsActive(prev => !prev)}
            active={isActive}
            displayOnlyTop={displayOnlyTop}
            isOnlyTop={isOnlyTop}
            setIsOnlyTop={setIsOnlyTop}
            closable={events.length !== 0}
            filter={openModal}>
          {(!isModerator && events.length !== 0) && <div className={s.content}>
            <div className={s.bottomSide}>
              <div className={classNames(s.filterRow, s.leftRow)}>
                <div>Events:</div>
                <div>
                  {events.length
                    ? events.map(event => (
                      <div key={event.id}>{`${event.name} - ${event.therapeuticArea} (${EVENT_TYPE_NAMES[event.eventType]})`}</div>
                    ))
                    : <div>No Events</div>}
                </div>
              </div>
            </div>
          </div>}
        </Card>
        : <FilterCardLoader />
      }
    </div>
  );
};

export default FilterCard;
