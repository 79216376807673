import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Progress } from '../../../../../../components/UI';
import s from './MultipleChoiceChart.module.scss';
import PhotoPopup from '../../../../../../components/PhotoPopup/PhotoPopup';
import CarouselPopup from '../../../../../../components/CarouselPopup/CarouselPopup';
import { getTop10Items } from '../../../../../../helpers/utils';
import VideoPopup from '../../../../../../components/VideoPopup/VideoPopup';
import NoItem from '../../../../../../components/NoItem/NoItem';

const MultipleChoiceChart = ({ data, comments = [], isOnlyTop }) => {
  const [ showPhoto, setShowPhoto ] = useState(null);
  const [ showPhotos, setShowPhotos ] = useState(null);
  const [ showVideo, setShowVideo ] = useState(null);
  const [ nullItems, setNullItems ] = useState(0);
  const files = useSelector(state => state.reportAnalys.analys.files);
  const videos = useSelector(state => state.reportAnalys.analys.videos);
  const getPhotoPaths = name => files.find(item => item.name === name) || null;
  const getVideoPaths = name => videos.find(item => item.name === name) || null;
  const {
    answers, answersCategories,
  } = data;

  const finalData = useMemo(() => (
    isOnlyTop ? getTop10Items(answers) : answers
  ), [ answers, isOnlyTop ]);

  useEffect(() => {
    if (finalData) {
      finalData.forEach((element) => {
        if (element.count === 0) setNullItems(prev => prev + 1);
      });
    }
  }, [ finalData ]);

  return (
    <div className={s.mcChart1Root}>
      <div className={s.mcChart1Content}>
        {finalData
          && finalData.map((item, i) => (
            item.count !== 0 && <div key={i} className={s.mcChartItem}>
              <div className={s.mcChartTitle}>
                {answersCategories && answersCategories.map(category => (
                  ![ 'IMAGE', 'Video', 'count' ].includes(category) && (
                    <section key={`${category}catIndx`} className={s.mcChartTitleBox}>
                      <div className={s.mcChartHeaderValue}>{item[category]}</div>
                    </section>
                  )))}
                {answersCategories === undefined && <section className={s.mcChartTitleBox}>
                  <div className={s.mcChartHeaderValue}>{item.value}</div>
                </section>}
              </div>
              <div className={s.mcChartInfo}>
                <div key={item}>
                  <Progress percent={item.percentage || 0} />
                </div>
                <div>{item.count} - ({item.percentage || 0}%)</div>
                <div className={s.mcChartImage}>
                  {answersCategories.map((category) => {
                    if (category === 'IMAGE') {
                      const image = item[category];
                      const isImage = item[category].split(',').length === 1;
                      const isImages = item[category].split(',').length > 1;
                      let imageFile;
                      let imageFiles;
                      if (isImage) {
                        imageFile = getPhotoPath(item[category]);
                      }
                      if (isImages) {
                        imageFiles = item[category].split(',').map(im => im.trim()).map(im => getPhotoPaths(im));
                      }
                      return (
                        (isImage || isImages) && <span key={item}>
                          {isImage && image && (
                            <span className={classNames({ [s.imageView]: image })}>
                              <span onClick={() => image && setShowPhoto(imageFile)}>Image</span>
                            </span>
                          )}
                          {isImages && imageFiles && (
                            <span className={classNames({ [s.imageView]: image })}>
                              <span onClick={() => imageFiles && setShowPhotos(imageFiles)}>
                                Images
                              </span>
                            </span>
                          )}
                        </span>
                      );
                    }
                    if (category === 'VIDEO') {
                      const video = item[category];
                      const videoFile = getVideoPaths(item[category]);
                      return (
                        <span
                          key={category}
                          length={answersCategories.length}
                        >
                          {video && videoFile && <span
                            className={classNames({ [s.imageView]: video })}
                            onClick={() => videoFile && setShowVideo(getVideoUrl(videoFile))}>
                            Video
                          </span>}
                        </span>
                      );
                    }
                    return true;
                  })}
                </div>
              </div>
            </div>
          ))}
        {nullItems === finalData.length && <div className={s.noEvent}><NoItem title={'Chart'} /></div>}
        {comments?.length > 0 && (
          <div className={s.commentsBoard}>
            <div className={s.commentHeader}>
              <div>Write-In Treatment:</div>
              <div>Count: {comments?.length}</div>
            </div>
            <div className={s.commentsBody}>
              {comments.map((comment, i) => (
                <div key={i} className={s.commentItem}>
                  {comment}
                </div>
              ))}
            </div>
          </div>
        )}
        {!finalData?.length && !comments.length && <div className={s.noEvent}><NoItem title={'Comments'} /></div>}
      </div>
      {showPhoto
        && <PhotoPopup img={showPhoto} onClose={() => setShowPhoto(null)} />
      }
      {showPhotos
        && <CarouselPopup files={showPhotos} onClose={() => setShowPhotos(null)} />
      }
      {showVideo
        && <VideoPopup video={showVideo} onClose={() => setShowVideo(null)} />
      }
    </div>
  );
  function getPhotoPath(name) {
    const file = files.find(item => item.name === name);
    return file ? process.env.REACT_APP_IMAGE_URL + file.mozJpeg : '';
  }

  function getVideoUrl(video) {
    return { videoUrl: process.env.REACT_APP_IMAGE_URL + video.videoUrl };
  }
};

export default MultipleChoiceChart;
