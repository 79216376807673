import { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import classNames from 'classnames';
import {
  Button, Datepicker, Input, Radio, Select, Timepicker,
} from '../../../../components/UI';
import Modal from '../../../../components/UI/Modal/Modal';
import { EVENT_TYPE_NAMES, VALID_IMAGE_TYPES } from '../../../../constants/names';
import { EVENT_TYPE_IDS } from '../../../../constants/ids';
import s from './FutureEventModal.module.scss';
import Row from '../../../../components/UI/Row/Row';
import { createModifyEventThunk, deleteEventThunk } from '../../../../redux/thunks/eventsDataThunk';
import { setConfirm } from '../../../../redux/slices/confirmSlice';
import AddPatientModal from './components/AddPatientModal/AddPatientModal';
import TimeZoneDropDown from './components/TimeZoneDropDown/TimeZoneDropDown';
import EventExpectedParticipants from './components/EventExpectedParticipants/EventExpectedParticipants';
import { modifySelectOptions } from '../../../../helpers/utils';
import { EVENT_CONFIGS_KEYS } from '../../../../configs/events.configs';
import { PATIENT_BASE_INFO_KEYS } from '../../../../configs/patients.configs';
import { getEventPatientsThunk } from '../../../../redux/thunks/patientsDataThunk';
import { setAlert } from '../../../../redux/slices/alertSlice';
import BI_Logo from '../../../../assets/nImages/BI_Logo.svg';

const {
  GENDER, AGE, FIRST_NAME, LAST_NAME, VISIT,
} = PATIENT_BASE_INFO_KEYS;

const eventTypes = Object.values(EVENT_TYPE_IDS);

const {
  AREA, COUNTRY, REGION, LANGUAGE,
} = EVENT_CONFIGS_KEYS;

const initialState = {
  eventType: EVENT_TYPE_IDS.VIRTUAL,
  name: '',
  language: '',
  city: '',
  country: '',
  region: '',
  startDate: new Date(),
  startTime: '10:00',
  endDate: null,
  therapeuticArea: '',
  expectedParticipants: 0,
  cases: [],
  timeZone: '',
  eventImage: '',
  eventLogo: '',
  eventBackground: '',
  customDisclaimer: '',
  inactiveTimer: '0:45',
  linkExpiryTimer: '4:00',
  resetAnalysisTimer: '3:00',
};

const FutureEventModal = ({
  closeModal, type, innerFormData, restartFetch, isView,
}) => {
  const dispatch = useDispatch();
  const { isCreateLoading, isDeleteLoading } = useSelector(state => state.eventsData);
  const eventConfigs = useSelector(state => state.userInfo.eventConfigs.configs);
  const [ formData, setFormData ] = useState(innerFormData || initialState);
  const [ isPatientModalActive, setIsPatientModalActive ] = useState(false);
  const [ eventImageUrl, setEventImageUrl ] = useState(null);
  const [ eventImageFile, setEventImageFile ] = useState(null);
  const isEventImageHave = formData.eventImage || eventImageUrl;
  const [ eventLogoUrl, setEventLogoUrl ] = useState(null);
  const [ eventLogoFile, setEventLogoFile ] = useState(null);
  const isEventLogoHave = formData.eventLogo || eventLogoUrl;
  const [ eventBackgroundUrl, setEventBackgroundUrl ] = useState(null);
  const [ eventBackgroundFile, setEventBackgroundFile ] = useState(null);
  const isEventBackgroundHave = formData.eventBackground || eventBackgroundUrl;
  const [ enableCustomDisclaimer, setEnableCustomDisclaimer ] = useState(formData.customDisclaimer !== '');
  useEffect(() => {
    fetch(BI_Logo)
      .then(response => response.blob())
      .then((blob) => {
        const logoFile = new File([ blob ], 'BI_Logo.svg', { type: 'image/svg+xml' });
        if (!formData.eventLogo && !isModify) {
          setEventLogoFile(logoFile);
          setEventLogoUrl(BI_Logo);
        }
      });
  }, []);

  const regionOptions = useMemo(() => (
    modifySelectOptions(eventConfigs[REGION])
  ), [ eventConfigs[REGION] ]);
  const [ regionSelect, setRegionSelect ] = useState(
    regionOptions.find(item => item.value === formData.region) || null,
  );

  const countryOptions = useMemo(() => (
    modifySelectOptions(eventConfigs[COUNTRY])
  ), [ eventConfigs[COUNTRY] ]);
  const [ countrySelect, setcountrySelect ] = useState(
    countryOptions.find(item => item.value === formData.country) || null,
  );

  const therapeuticOptions = useMemo(() => (
    modifySelectOptions(eventConfigs[AREA])
  ), [ eventConfigs[AREA] ]);
  const [ therapeuticSelect, setTherapeuticSelect ] = useState(
    therapeuticOptions.find(item => item.value === formData.therapeuticArea) || null,
  );

  const languageOptions = useMemo(() => (
    modifySelectOptions(eventConfigs[LANGUAGE])
  ), [ eventConfigs[LANGUAGE] ]);
  const [ languageSelect, setLanguageSelect ] = useState(
    languageOptions.find(item => item.value === formData.language) || null,
  );

  const isModify = type === 'Modify';
  const startDate = typeof (formData?.startDate) === 'string'
    ? getDateValues(formData.startDate) : formData.startDate;
  const endDate = typeof (formData?.startDate) === 'string'
    ? getDateValues(formData.endDate) : formData.endDate;

  function getDateValues(inputDate) {
    const parts = inputDate.split('-');
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }

  const formHandler = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const removePatientCase = (id) => {
    setFormData(prev => ({ ...prev, cases: prev.cases.filter(item => item.id !== id) }));
  };

  const deleteEvent = () => {
    dispatch(deleteEventThunk({
      id: formData.id, closeModal, restartFetch,
    }));
  };

  const onDeleteClick = () => {
    dispatch(setConfirm(deleteEvent));
  };

  const onTypeClick = (value) => {
    formHandler('eventType', value);
  };

  const eventImageHandler = async (e) => {
    if (!e.target.files.length) return;
    const file = e.target.files[0];
    if (!VALID_IMAGE_TYPES.includes(file.type)) {
      return dispatch(setAlert({ message: 'Invalid image type' }));
    }
    formHandler('eventImage', null);
    setEventImageUrl(URL.createObjectURL(file));
    setEventImageFile(file);
  };

  const eventBackgroundHandler = async (e) => {
    if (!e.target.files.length) return;
    const file = e.target.files[0];
    if (!VALID_IMAGE_TYPES.includes(file.type)) {
      return dispatch(setAlert({ message: 'Invalid image type' }));
    }
    formHandler('eventBackground', null);
    setEventBackgroundUrl(URL.createObjectURL(file));
    setEventBackgroundFile(file);
  };

  const eventLogoHandler = async (e) => {
    if (!e.target.files.length) return;
    const file = e.target.files[0];
    if (!VALID_IMAGE_TYPES.includes(file.type)) {
      return dispatch(setAlert({ message: 'Invalid image type' }));
    }
    formHandler('eventLogo', null);
    setEventLogoUrl(URL.createObjectURL(file));
    setEventLogoFile(file);
  };

  const removeEventImage = () => {
    setEventImageUrl(null);
    formHandler('eventImage', null);
  };

  const removeEventBackground = () => {
    setEventBackgroundFile(null);
    setEventBackgroundUrl(null);
    formHandler('eventBackground', null);
  };

  const removeEventLogo = () => {
    setEventLogoUrl(null);
    setEventLogoFile(null);
    formHandler('eventLogo', null);
  };

  const isOkDisable = !formData.name || !formData.cases?.length
  || !therapeuticSelect || !countrySelect || !regionSelect || !languageSelect || isDeleteLoading
    || !formData.startTime || !formData.timeZone
    || !formData.expectedParticipants || formData.expectedParticipants === '0';

  const isAddPatientDisabled = !languageSelect || !therapeuticSelect;

  const onFormSubmit = () => {
    if (isOkDisable) return;
    const formatedData = {
      ...formData,
      startDate: dayjs(formData.startDate).format('YYYY-MM-DD'),
      endDate: formData.endDate
        ? dayjs(formData.endDate).format('YYYY-MM-DD')
        : null,
      startTime: formData.startTime,
      cases: formData.cases.map(el => el.id),
      country: countrySelect?.value,
      region: regionSelect?.value,
      therapeuticArea: therapeuticSelect?.value,
      language: languageSelect?.value,
      timeZone: formData.timeZone,
      eventImage: formData.eventImage,
      eventLogo: formData.eventLogo,
      eventBackground: formData.eventBackground,
      customDisclaimer: formData.customDisclaimer,
      inactiveTimer: formData.inactiveTimer,
      linkExpiryTimer: formData.linkExpiryTimer,
      resetAnalysisTimer: formData.resetAnalysisTimer,
    };
    dispatch(
      createModifyEventThunk({
        isModify,
        data: formatedData,
        closeModal,
        restartFetch,
        eventImageFile,
        eventLogoFile,
        eventBackgroundFile,
      }),
    );
  };

  const onPatientModalOk = (checkedCases) => {
    formHandler('cases', checkedCases);
    setIsPatientModalActive(false);
  };

  const getPatientsByLanguage = page => dispatch(getEventPatientsThunk({
    language: languageSelect?.value, area: therapeuticSelect?.value, page,
  }));

  return (
    <Modal
      title={`${isView ? 'View' : type} Event`}
      actions={
        <div className={s.actions}>
          <div>

            {isModify
            && <Button
              loading={isDeleteLoading}
              onClick={onDeleteClick}
              disabled={isCreateLoading}
              variant='danger'>Delete</Button>}
          </div>
          <div>

            <Button onClick={closeModal} className={s.cancelButton} variant='light'>Cancel</Button>
            <Button
              disabled={isOkDisable}
              loading={isCreateLoading}
              onClick={onFormSubmit}>OK</Button>
          </div>
        </div>
    }>
      <div className={classNames(s.content, { [s.view]: isView })}>
        <div className={s.row}>
          <div>Name of Event<span className={s.required}>*</span></div>
          <div>
            <Input
              name='name'
              onChange={({ target }) => !isView && formHandler(target.name, target.value)}
              value={formData.name} />
          </div>
        </div>
        <div className={s.row}>
          <div>Therapeutic Area<span className={s.required}>*</span></div>
          <div>
            <Select
              isDisabled={isView || formData.cases?.length > 0}
              isSearchable
              placeholder='Select Therapeutic Area...'
              value={therapeuticSelect}
              onChange={value => setTherapeuticSelect(value)}
              options={therapeuticOptions} />
          </div>
        </div>
        <div className={s.row}>
          <div>Language<span className={s.required}>*</span></div>
          <div>
            <Select
              isDisabled={formData.cases?.length > 0}
              isSearchable
              placeholder='Select Language...'
              value={languageSelect}
              onChange={value => setLanguageSelect(value)}
              options={languageOptions} />
          </div>
        </div>
        <div className={s.row}>
          <div>Approved Patient Case<span className={s.required}>*</span></div>
          <div>
            {formData.cases.length
              ? formData.cases.map((item) => {
                const { baseInfo } = item;
                return (
                  <div key={item.id} className={s.patientRowWrapper}>
                    <Row className={s.patientRow}>
                      <div>
                        {`${baseInfo[GENDER]} ${baseInfo[FIRST_NAME]}${baseInfo[LAST_NAME]
                          ? ` ${baseInfo[LAST_NAME]}` : ''}, ${baseInfo[AGE]} - ${baseInfo[VISIT]} Visit`}
                      </div>
                    </Row>
                    {!isView
                  && <div onClick={() => removePatientCase(item.id)} className={s.recycleIcon} />}
                  </div>
                );
              })
              : <div>
                {!isView
                && <Button
                  disabled={isAddPatientDisabled}
                  onClick={() => setIsPatientModalActive(true)}
                  className={s.patientButton}>
                  + Add Patient Case
                </Button>}
              </div>
            }
          </div>
        </div>
        {!!formData.cases.length && <div className={s.row}>
          <div />
          <div>
            {!isView
            && <Button
              disabled={isAddPatientDisabled}
              onClick={() => setIsPatientModalActive(true)}
              className={s.patientButton}>
              + Add Patient Case
            </Button>}
          </div>
        </div>}
        <div className={s.row}>
          <div>Date of Event<span className={s.required}>*</span></div>
          <div className={s.dateBoard}>
            <div className={s.datepicker}>
              <Datepicker
                dateFormat="MMM d, yyyy"
                disabled={isView}
                minDate={Date.now() - 8.64e+7}
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={(date) => {
                  formHandler('startDate', date);
                  if (Date.parse(endDate) < Date.parse(date)) {
                    formHandler('endDate', date);
                  }
                }} />
            </div>
            <div className={s.timeLabel}>
              Time<span className={s.required}>*</span>
            </div>
            <div className={s.timePicker}>
              <Timepicker
                onTimeChange={time => time && formHandler('startTime', time)}
                time={formData.startTime}
              />
            </div>
          </div>
        </div>
        <div className={s.row}>
          <div>Time Zone<span className={s.required}>*</span></div>
          <div>
            <TimeZoneDropDown
              toggleTimeZone={timeZone => timeZone && formHandler('timeZone', timeZone)}
              timeZone={formData.timeZone}
              isView={isView} />
          </div>
        </div>
        <div className={s.row}>
          <div>Region<span className={s.required}>*</span></div>
          <div>
            <Select
              isDisabled={isView}
              isSearchable
              placeholder='Select Region...'
              value={regionSelect}
              onChange={value => setRegionSelect(value)}
              options={regionOptions} />
          </div>
        </div>
        <div className={s.row}>
          <div>Country<span className={s.required}>*</span></div>
          <div>
            <Select
              isDisabled={isView}
              isSearchable
              placeholder='Select Country...'
              value={countrySelect}
              onChange={value => setcountrySelect(value)}
              options={countryOptions} />
          </div>
        </div>
        <div className={s.row}>
          <div>City</div>
          <div>
            <Input
              name='city'
              onChange={({ target }) => !isView && formHandler(target.name, target.value)}
              value={formData.city} />
          </div>
        </div>
        <div className={s.row}>
          <div>Type of Meeting<span className={s.required}>*</span></div>
          <div className={s.radioBoard}>
            {eventTypes.map(item => (
              <div
                key={item}
                onClick={() => onTypeClick(item)}
                className={s.radioItem}>
                <div>
                  <Radio size='30px' active={item === formData.eventType} />
                </div>
                <div className={s.radioLable}>{EVENT_TYPE_NAMES[item]}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={s.row}>
          <div>Expected No. of Participants<span className={s.required}>*</span></div>
          <div>
            <EventExpectedParticipants
              value={formData.expectedParticipants}
              toggleValue={value => !isView && formHandler('expectedParticipants', value)} />
          </div>
        </div>
        <div className={s.row}>
          <div>Event Logo <div>(jpg, png, or svg)</div></div>
          <label className={classNames(s.chooseButton, s.grayButton)} htmlFor='chooseEventLogo'>
            Choose
          </label>
          <span className={s.inputTextInfo}>220px max x 65px</span>
          <input
            onChange={eventLogoHandler}
            type='file'
            id='chooseEventLogo'
            className={s.fileInput}
            accept=".jpg,.jpeg,.png,.jfif,.webp,.svg" />
          {isEventLogoHave && <span
            onClick={removeEventLogo}
            className={classNames(s.recycleIcon, { [s.disabled]: !isEventLogoHave })} />
          }
        </div>
        <div className={s.eventImageContainer}>
          {(formData.eventLogo || eventLogoUrl) && <div className={s.event_logo_preview}>
            <img
              src={formData.eventLogo ? `${process.env.REACT_APP_IMAGE_URL}${formData.eventLogo}` : eventLogoUrl}
              alt='EventLogo'
            />
          </div>}
        </div>
        <div className={s.row}>
          <div> Custom Event Introduction  in transparent background</div>
          <label className={classNames(s.chooseButton, s.grayButton, s.customEventImage)} htmlFor='chooseEventImage'>
            Choose
          </label>
          {isEventImageHave && <span
            onClick={removeEventImage}
            className={classNames(s.recycleIcon, { [s.disabled]: !isEventImageHave })} />}
          <input
            onChange={eventImageHandler}
            type='file'
            id='chooseEventImage'
            className={s.fileInput}
            accept=".jpg,.jpeg,.png,.jfif,.webp,.svg" />
        </div>
        <div className={s.eventImageContainer}>
          {isEventImageHave && <div className={s.event_img_preview}>
            <img
              src={eventImageUrl || `${process.env.REACT_APP_IMAGE_URL}${formData.eventImage}`}
              alt='EventImage'
            />
          </div>}
        </div>
        <div className={s.row}>
          <div>Watermark in transparent png/svg (840 x 430px)</div>
          <label className={classNames(s.chooseButton, s.grayButton, s.customEventBackground)} htmlFor='chooseEventBackground'>
            Choose
          </label>
          {isEventBackgroundHave && <span
            onClick={removeEventBackground}
            className={classNames(s.recycleIcon, { [s.disabled]: !isEventBackgroundHave })} />}
          <input
            onChange={eventBackgroundHandler}
            type='file'
            id='chooseEventBackground'
            className={s.fileInput}
            accept=".jpg,.jpeg,.png,.jfif,.webp,.svg" />
        </div>
        <div className={s.eventBackgroundContainer}>
          {isEventBackgroundHave && <div className={s.event_bg_preview}>
            <img
              src={eventBackgroundUrl || `${process.env.REACT_APP_IMAGE_URL}${formData.eventBackground}`}
              alt='EventBackground'
            />
          </div>}
        </div>
        <div className={s.row}>
          <div>Custom <div>Disclaimer Text</div></div>
          {!enableCustomDisclaimer && <label
            onClick={() => setEnableCustomDisclaimer(true)}
            className={classNames(s.chooseButton, s.grayButton, s.customEventBackground)}
            htmlFor='addCustomDisclaimer'>
            Add
          </label>}
          {enableCustomDisclaimer && <div className={s.customDisclaimerTextBoxHolder}>
            <textarea
              onChange={({ target }) => !isView && formHandler(target.name, target.value)}
              className={s.customDisclaimerTextBox}
              name='customDisclaimer'
              value={formData.customDisclaimer}
              rows={5}
             />
            <div
              onClick={() => {
                setEnableCustomDisclaimer(false);
                formData.customDisclaimer = '';
              }}
              className={classNames(s.recycleIcon, { [s.disabled]: !enableCustomDisclaimer })} />
          </div>}
        </div>
        <div className={s.row}>
          <div className={s.timeLabel}>
            Inactive Timer
          </div>
          <div className={s.timePicker}>
            <Timepicker
              onTimeChange={time => time && formHandler('inactiveTimer', time)}
              time={formData.inactiveTimer}
            />
          </div>
        </div>
        <div className={s.row}>
          <div className={s.timeLabel}>
            Event Link Expiration
          </div>
          <div className={s.timePicker}>
            <Timepicker
              onTimeChange={time => time && formHandler('linkExpiryTimer', time)}
              time={formData.linkExpiryTimer}
              showDayConfig
            />
          </div>
          <div className={s.timeTextLabel}>
            After Scheduled
            <div>Event Start Time</div>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.timeLabel}>
            Reset Analysis
          </div>
          <div className={s.timePicker}>
            <Timepicker
              onTimeChange={time => time && formHandler('resetAnalysisTimer', time)}
              time={formData.resetAnalysisTimer}
              showDayConfig
            />
          </div>
          <div className={s.timeTextLabel}>
            Automatically
            <div>Before Event</div>
          </div>
        </div>
      </div>
      {/* MODAL */}
      {isPatientModalActive
      && <AddPatientModal
        patientCases={formData.cases}
        onOkClick={onPatientModalOk}
        fetchCases={getPatientsByLanguage}
        closeModal={() => setIsPatientModalActive(false)} />}
    </Modal>
  );
};

export default FutureEventModal;
