import { Chart } from 'react-google-charts';
import s from './PieChartView.module.scss';
import NoItem from '../../../../../../components/NoItem/NoItem';

export function getOrdinal(number) {
  const suffixes = [ 'th', 'st', 'nd', 'rd' ];
  const absNumber = Math.abs(number);
  const lastDigit = absNumber % 10;
  const lastTwoDigits = absNumber % 100;

  // Special case for 11, 12, and 13
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return `${absNumber} th`;
  }

  // Default logic for other numbers
  const suffix = suffixes[lastDigit] || suffixes[0];
  return `${absNumber}${suffix} Rank`;
}

function generateRandomColors(length) {
  const colors = [];
  for (let i = 0; i < length; i += 1) {
    const color = getRandomColor();
    colors.push(color);
  }
  return colors;
}

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    const randomIndex = Math.floor(Math.random() * 16);
    color += letters[randomIndex];
  }
  return color;
}

const PieChartView = ({ data }) => {
  const {
    charts,
  } = data;
  const pieColors = [ '#4FB1C2', '#FF7A00', '#1D2F56', ...(generateRandomColors(Object.keys(charts).length - 3)) ];
  return (
    <div className={s.pieChartRoot}>
      <div className={s.pieChartContent}>
        <div className={s.pieChartItem}>
          {charts
            && Object.keys(charts).map((el, index) => {
              const options = {
                is3D: true,
                backgroundColor: 'transparent',
                colors: pieColors,
                legend: {
                  position: 'none',
                },
              };
              return (<div className={s.pieChartInfo} key={el}>
                <div className={s.pieChartLabel}>{getOrdinal(el)}</div>
                <Chart
                  key={getOrdinal(el)}
                  chartType='PieChart'
                  data={charts[el]}
                  options={options}
                  width='250px'
                  height='250px'
                />
              </div>);
            })}
        </div>
        <div className={s.pieChartLegend}>
          {charts && charts[1].map((el, idx) => idx !== 0
            && (<div className={s.pieChartLegendHolder} key={el}>
              <span className={s.pieChartLegendColor} style={{ backgroundColor: pieColors[idx - 1] }} />
              <span className={s.pieChartLegendLabel}>{el[0]}</span>
            </div>))}
        </div>
        {!charts.length && <div className={s.noEvent}><NoItem title={'Chart'} /></div>}
      </div>
    </div>
  );
};

export default PieChartView;
