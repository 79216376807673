import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CarouselPopup from '../../../../../components/CarouselPopup/CarouselPopup';
import PhotoPopup from '../../../../../components/PhotoPopup/PhotoPopup';
import { Table } from '../../../../../components/UI';
import VideoPopup from '../../../../../components/VideoPopup/VideoPopup';
import { getTop10Items } from '../../../../../helpers/utils';
import s from './MultipleChoice.module.scss';

const MultipleChoice = ({ data, isOnlyTop }) => {
  const [ showPhoto, setShowPhoto ] = useState(null);
  const [ showPhotos, setShowPhotos ] = useState(null);
  const [ showVideo, setShowVideo ] = useState(null);
  const files = useSelector(state => state.reportAnalys.analys.files);
  const videos = useSelector(state => state.reportAnalys.analys.videos);
  const getPhotoPaths = name => files.find(item => item.name === name) || null;
  const getVideoPaths = name => videos.find(item => item.name === name) || null;
  const { answers, comments, answersCategories } = data;

  const finalData = useMemo(() => (
    isOnlyTop ? getTop10Items(answers) : answers
  ), [ answers, isOnlyTop ]);

  return (
    <div className={s.multipleChoiceContainer}>
      <Table>
        <Table.Header>
          {answersCategories.map(header => (
            <Table.HeaderCell
              key={header}
              className={s.tableHeader}
              length={answersCategories.length}>
              <div>
                {header}
              </div>
            </Table.HeaderCell>
          ))}
        </Table.Header>
        <Table.Body>
          {finalData.map((row, i) => (
            <Table.Row key={row.id}>
              {answersCategories.map((item) => {
                if (item === 'IMAGE') {
                  const image = row[item];
                  const isImage = row[item].split(',').length === 1;
                  const isImages = row[item].split(',').length > 1;
                  let imageFile;
                  let imageFiles;
                  if (isImage) {
                    imageFile = getPhotoPath(row[item]);
                  }
                  if (isImages) {
                    imageFiles = row[item].split(',').map(y => y.trim()).map(y => getPhotoPaths(y));
                  }
                  return <Table.Cell
                    key={item}
                    length={answersCategories.length}
                    className={classNames(s.tableRow, { [s.pointer]: image })}>
                    { isImage && <div className={classNames({ [s.imageView]: image })}>
                      {
                        <div onClick={() => image && setShowPhoto(imageFile)}>{image ? 'View' : '-'}</div>
                      }
                      </div>}
                    {isImages && <div className={classNames({ [s.imageView]: image })}>
                      {
                        <div onClick={() => imageFiles && setShowPhotos(imageFiles)}>{imageFiles ? 'View' : '-' }</div>
                      }
                      </div>}
                  </Table.Cell>;
                }
                if (item === 'VIDEO') {
                  const video = row[item];
                  const videoFile = getVideoPaths(row[item]);
                  return <Table.Cell
                    key={item}
                    length={answersCategories.length}
                    className={classNames(s.tableRow, { [s.pointer]: video })}>
                    <div onClick={() => videoFile && setShowVideo(getVideoUrl(videoFile))}>{videoFile ? 'View' : '-' }</div>
                  </Table.Cell>;
                }
                return <Table.Cell
                  className={s.tableRow}
                  length={answersCategories.length}
                  key={item}>
                  <div className={classNames({
                    [s.lastCell]: answersCategories[answersCategories.length - 1] === item,
                  })}>
                    {![ null, undefined ].includes(row[item]) ? String(row[item]).replace('***', '').split('\n').map((paragraph, idx) => (
                      <p key={IDBIndex} className='paragraph'>{paragraph}</p>
                    )) : '-'}
                  </div>
                </Table.Cell>;
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {comments
      && <div className={s.commentsBoard}> <Table>
        <Table.Header>
          <Table.HeaderCell className={s.tableHeader} width={'100%'}>
            <div className={s.commentHeader}>
              <div>Write-In Treatment</div><div>Count: {comments.length}</div>
            </div>
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {comments.map((comment, i) => (
            <Table.Row key={i}>
              <Table.Cell className={s.tableRow} width={'100%'}>
                <div>{comment}</div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
        </div>
      }
      {showPhoto
        && <PhotoPopup img={showPhoto} onClose={() => setShowPhoto(null)} />
      }
      {showPhotos
        && <CarouselPopup files={showPhotos} onClose={() => setShowPhotos(null)} />
      }
      {showVideo
        && <VideoPopup video={showVideo} onClose={() => setShowVideo(null)} />
      }
    </div>
  );

  function getPhotoPath(name) {
    const file = files.find(item => item.name === name);
    return file ? process.env.REACT_APP_IMAGE_URL + file.mozJpeg : '';
  }
  function getVideoUrl(video) {
    return { videoUrl: process.env.REACT_APP_IMAGE_URL + video.videoUrl };
  }
};

export default MultipleChoice;
