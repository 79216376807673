import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from '../../pages/Login/Login';
import PrivateRoute from './PrivateRoute';

const Routes = ({ validRoutes }) => {
  const isAuth = useSelector(state => state.userInfo.isAuth);

  return (
    <Switch>
      {validRoutes.map((route) => {
        if (!route.multi) {
          return (
            <PrivateRoute
              exact
              key={route.name}
              path={route.path}
              Component={route.Component}
          />
          );
        }
        return route.childs.map(child => (
          <PrivateRoute
            exact
            key={child.name}
            path={route.path + child.path}
            Component={child.Component}
          />
        ));
      })}
      <Route
        exact
        path='/signIn'
        render={({ location }) => (
          !isAuth
            ? <Login />
            : <Redirect to={
              location?.state?.from.pathname
              || (validRoutes[0] && (validRoutes[0].multi
                ? validRoutes[0].path + validRoutes[0].childs[0].path
                : validRoutes[0].path))
              || '/signIn'} />
        )} />
      <Route
        path='*'
        render={() => <Redirect to={
          (validRoutes[0] && (validRoutes[0].multi
            ? validRoutes[0].path + validRoutes[0].childs[0].path
            : validRoutes[0].path)) || '/signIn'
            } />} />
    </Switch>
  );
};

export default Routes;
