import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import s from './Wrapper.module.scss';
import Header from '../Header/Header';
import Menu from '../Menu/Menu';
import Routes from '../Routes/Routes';
import { getValidRoutes } from '../Routes/routes.configs';
import Alert from '../Alert/Alert';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import FiltersModal from '../FiltersModal/FiltersModal';
import { selectIsModerator } from '../../redux/slices/userInfoSlice';
import { selectModeratorActiveEvent } from '../../redux/slices/moderatorSlice';

const Wrapper = () => {
  const role = useSelector(state => state.userInfo.role);
  const isModerator = useSelector(selectIsModerator);
  const location = useLocation();
  const isLoginPage = location.pathname === '/signIn';
  const { analys } = useSelector(state => state.reportAnalys);
  const validRoutes = useMemo(() => getValidRoutes(role), [ role ]);
  const activeEvent = useSelector(selectModeratorActiveEvent);

  return (
    <div className={s.wrapperOuter}>
      {!isLoginPage && (
        <div className={s.wrapperHeader}>
          <Header />
        </div>
      )}
      <div className={s.wrapperInner}>
        {!isLoginPage && (
          <div className={s.wrapperMenu}>
            <Menu validRoutes={validRoutes} />
          </div>
        )}
        <div
          className={classNames(s.wrapper, {
            [s.loginPage]: isLoginPage,
          })}>
          {!isLoginPage && activeEvent?.eventBackground && (
            <div className={s.eventBackgroundContainer}>
              <img
                className={s.event_background}
                src={`${process.env.REACT_APP_IMAGE_URL}${activeEvent?.eventBackground}`}
                alt='Event Background'
              />
            </div>
          )}
          <Routes validRoutes={validRoutes} />
        </div>
      </div>
      <Alert />
      {/* modals */}
      <ConfirmModal />
      {!isModerator && <FiltersModal />}
    </div>
  );
};

export default Wrapper;
