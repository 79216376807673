import { USER_ROLES_IDS, EVENT_TYPE_IDS } from './ids';

const {
  EC_ADMIN, EVENT_MODERATOR, SUPER_USER, ANALYSIS_ADMIN,
} = USER_ROLES_IDS;
const { VIRTUAL, FACE, BOTH } = EVENT_TYPE_IDS;

export const RESPONSE_STATUSES = {
  SUCCESS: 'Success',
  CREATED: 'Created',
};

export const USER_ROLES_NAMES = {
  [EC_ADMIN]: 'Event and Case Admin',
  [EVENT_MODERATOR]: 'Event Moderator',
  [SUPER_USER]: 'Admin',
  [ANALYSIS_ADMIN]: 'Analysis Admin',
};

export const EVENT_TYPE_NAMES = {
  [VIRTUAL]: 'Virtual',
  [FACE]: 'In-person',
  [BOTH]: 'Both',
};

export const GENDER_TYPES = {
  MR: 'Mr.',
  MRS: 'Mrs.',
  MS: 'Ms.',
};

export const ORDER_TYPES = {
  DESC: 'DESC',
  ASC: 'ASC',
};

export const PERCENT_COUNT_TYPES = {
  percentage: 'percentages',
  count: 'count',
  both: 'both',
};

export const ALERT_TYPES = {
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const GENDER_NAMES = {
  [GENDER_TYPES.MR]: 'Male',
  [GENDER_TYPES.MRS]: 'Female',
  [GENDER_TYPES.MS]: 'Female',
};

export const EVENT_KEYS = {
  AREA: 'therapeuticArea',
  DATE_FROM: 'dateFrom',
  DATE_TILL: 'dateTill',
  REGION: 'region',
  COUNTRY: 'country',
  CITY: 'city',
  EVENT_TYPE: 'eventType',
  CASE_LIST: 'cases',
  LANGUAGES: 'language',
  LOCATIONS: 'locations',
};

export const VISIT_NAMES = {
  1: 'First (1st)',
  2: 'Second (2nd)',
  3: 'Third (3rd)',
  4: 'Fourth (4th)',
  5: 'Fifth (5th)',
};

export const VALID_IMAGE_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/jfif',
  'image/webp',
  'image/svg+xml',
];

export const VALID_VIDEO_TYPES = [
  'video/mp4', 'video/quicktime', 'video/avi', 'audio/ogg', 'video/x-ms-wmv', 'video/webm',
];

export const VALID_ICONS_TYPES = [
  'image/png', 'image/jpg', 'image/jpeg', 'image/jfif', 'image/svg', 'image/svg+xml, image/webp',
];

export const THERAPEUTIC_AREAS = {
  DIABETES: 'Diabetes',
  CARDIOVASCULAR: 'Cardiovascular',
  IMMUNOLOGY: 'Immunology',
};

export const ANALYSIS_ROUTES = {
  GENERAL: '/overallAnalysis/general',
  PAST: '/overallAnalysis/past',
  SATISFICATION: '/overallAnalysis/customerSatisfaction',
  QUESTION: '/caseEventAnalysis/questionsAnswers',
  REPORT: '/caseEventAnalysis/report',
};

const {
  GENERAL, PAST, SATISFICATION, QUESTION, REPORT,
} = ANALYSIS_ROUTES;

export const ANALYSIS_KEYS = {
  [GENERAL]: 'general',
  [PAST]: 'past',
  [SATISFICATION]: 'satisfication',
  [QUESTION]: 'question',
  [REPORT]: 'report',
};

export const TEMPLATE_TYPES = {
  INTERVIEW: 'interview',
  TABLE_VIEW: 'noGroups',
  TABLE_VIEW_GROUPS: 'groups',
  IMAGE_INTERACTIVE: 'imageInteractive',
  MULTIPE_CHOICE: 'multipleChoice',
  RANKING: 'ranking',
  MULTIPLE_CHOICE_GROUPS: 'mcGroups',
};

export const QUESTION_KEYS = {
  CHAPTER_TYPE: 'type',
  CHAPTER_NAME: 'name',
  QUESTION: 'question',
  ICON: 'icon',
  SELECT_ALL: 'Have "All Above"',
  ALLOW_WRITE: 'Allow Write-Ins',
};
