import classNames from 'classnames';
import { Table } from '../../../../../components/UI';
import s from './Question2.module.scss';
import { PERCENT_COUNT_TYPES } from '../../../../../constants/names';
import { getOrdinal } from '../../../Report/Templates/Chart/PieChart/PieChartView';

const infoTypes = Object.keys(PERCENT_COUNT_TYPES);

function generateTableHeaders(data) {
  const headers = [];
  data.forEach((el, idx) => {
    headers.push({
      key: idx + 1,
      name: getOrdinal(idx + 1),
      width: `${50 / data.length + 1}%`,
    });
    if (idx === data.length - 1) {
      headers.push({
        key: idx + 1,
        name: 'Total',
        width: `${50 / data.length + 1}%`,
      });
    }
  });
  return headers;
}

const Question2 = ({ data }) => {
  const infoType = infoTypes[0];
  const tableHeaders = generateTableHeaders(data);

  return (
    <div className={s.question2Container}>
      <Table>
        <Table.Header>
          <Table.HeaderCell className={s.tableHeader} width={'50%'}>
            <div>Answer</div>
          </Table.HeaderCell>
          {tableHeaders.map((header, i) => {
            if (i < 0) return null;
            return (
              <Table.HeaderCell
                key={header.key}
                className={s.tableHeader}
                width={header.width}>
                <div className={s.rankHeader}>
                  <div>{header.name}</div>
                </div>
              </Table.HeaderCell>
            );
          })}
        </Table.Header>
        <Table.Body>
          {data.map((info, i) => (
            <Table.Row key={i}>
              <Table.Cell width='50%'>{info.value}</Table.Cell>
              {tableHeaders.map((item, idx) => {
                if (idx < 0) return null;
                return (
                  <Table.Cell
                    className={classNames(s.tableRow, {
                      [s.maxValue]:
                        idx !== tableHeaders.length - 1
                        && info.maxValue === info[infoType][item.key],
                    })}
                    width={item.width}
                    key={item.key}>
                    {idx !== tableHeaders.length - 1 && (
                      <div>
                        {`${info[infoType][item.key]}${infoType === infoTypes[0] ? '%' : ''}`}
                      </div>
                    )}
                    {idx === tableHeaders.length - 1 && (
                      <div className={s.rankTotal}>100%</div>
                    )}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default Question2;
